.App {
    text-align: center;
}

@media (min-width: 320px) {
    .NavBarMobile {
        display: inline;
    }
}

@media (min-width: 375px) {
    .NavBarMobile {
        display: inline;
    }
}
@media (min-width: 425px) {
    .NavBarMobile {
        display: inline;
    }
}
@media (min-width: 768px) {
    .NavBarMobile {
        display: inline;
    }
}

@media (min-width: 1440px) {
    .NavBarMobile {
        display: none;
    }
}

/* ------------- */
@media (min-width: 320px) {
    .NavBarWeb {
        display: none;
    }
}

@media (min-width: 375px) {
    .NavBarWeb {
        display: none;
    }
}
@media (min-width: 425px) {
    .NavBarWeb {
        display: none;
    }
}
@media (min-width: 768px) {
    .NavBarWeb {
        display: none;
    }
}

@media (min-width: 1440px) {
    .NavBarWeb {
        display: inline;
    }
}
